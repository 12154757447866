<template>
    <modal ref="modalCambiarVigencia" titulo="Cambiar Vigencia" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="cambiarVigenciaMasivo">
        <div class="row mx-0 justify-center">
            <div class="col-auto pt-2 text-general f-14">
                Vigencia
            </div>
            <div class="col-8">
                <div class="row mx-0">
                    <div class="col-1 d-middle-center px-0">
                        Inicio
                    </div>
                    <div class="col">
                       <el-date-picker
                                v-model="form.fecha_inicio"
                                type="datetime"
                                class="w-100"
                                placeholder="-"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :clearable="false"
                                />
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-1 d-middle-center px-0">
                        Fin
                    </div>
                    <div class="col">
                        <el-date-picker
                                v-model="form.fecha_fin"
                                type="datetime"
                                class="w-100"
                                placeholder="-"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :clearable="false"
                                />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
export default {
    data(){
        return{
            form:{
                fecha_inicio:null,
                fecha_fin:null
            },
            clientes:[]
        }
    },
    computed:{
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
    },
    methods: {
        toggle(array_clientes){
            this.clientes = array_clientes
            this.$refs.modalCambiarVigencia.toggle();
        },
        async cambiarVigenciaMasivo(){
            if(this.form.fecha_inicio === null) return
            if(this.form.fecha_fin === null) return

            if(this.form.fecha_inicio > this.form.fecha_fin){
                this.notificacion('Advertencia','La fecha inicio No puede ser mayor a la fecha fin.','warning')
                return
            }

            try {
                this.form.id_cupon = this.id_cupon
                this.form.clientes = this.clientes
                const {data} = await Cupones.cambiarVigenciaMasivo(this.id_cupon, this.form)
                this.$emit('update')
                this.notificacion('Exito',`Se ha cambiado la vigencia para los ${this.clientes.length} clientes seleccionados`,'success')
                this.$refs.modalCambiarVigencia.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>